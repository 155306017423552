// Backwards compatibility, see https://github.com/zloirock/core-js/blob/master/docs/2019-03-19-core-js-3-babel-and-a-look-into-the-future.md
import 'core-js/stable';
import 'regenerator-runtime/runtime';
// Semantic UI CSS (special case for this file).
// Until Semantic UI comes with a proper build option we include only the basic
// CSS theme here. If we need to do custom theming later we may use the
// semantic-ui-less package and compile the less files. Akin to what is done
// here for the boiler plate Webpack 3 example here:
// https://github.com/Semantic-Org/Semantic-UI-React/tree/master/examples/webpack3
import 'semantic-ui-css/semantic.min.css';
// Lib
import React from 'react';
import { createRoot } from 'react-dom/client';
import { StyleSheet, css } from 'aphrodite';
// Local
import Backdrop from '../../components/Backdrop';
import LoginForm from './LoginForm';

const s = StyleSheet.create({
  box: {
    position: 'fixed',
    width: '100%',
    height: '100%',
  },
});

const App = () => (
  <div className={css(s.box)}>
    <Backdrop />
    <LoginForm />
  </div>
);

const container = document.getElementById('app');
const root = createRoot(container!);
root.render(<App />);
