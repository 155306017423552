/* eslint-disable no-console */
// Lib
import debug from 'debug';
import Bugsnag from '@bugsnag/js';
// Local
import onceEvery from './onceEvery';

// Namespace for debug logging
const namespace = 'elv:*';

// Enable logging by default, except in production
if (!PRODUCTION) {
  debug.enable(namespace);
} else {
  debug.disable(namespace);
}

// Calling this function allows disabling/enabling logging, for
// debugging purposes. This function is called by Client.tsx and
// cannot be called earlier because performance globals must be
// ready when this function is called.
export function enableLogToggle() {
  if (window.elv?.debug) {
    window.elv.debug.disableLogging = () => {
      console.log('logging disabled');
      debug.disable(namespace);
    };
    window.elv.debug.enableLogging = () => {
      console.log('logging enabled');
      debug.enable(namespace);
    };
  } else {
    console.warn('performance globals not found, could not add debug functions to toggle logging on/off');
  }
}

/**
 * Simple wrapper around the great "debug" library.
 * Reduces logger setup in all files from 5 lines of code down to 2 lines.
 */
export default (componentNamespace) => {
  // log() goes to console.log
  const log = debug(componentNamespace);

  // onceeverylog() goes to console.log
  const privateOnceeverylog = debug(componentNamespace);
  privateOnceeverylog.log = console.log.bind(console);
  const onceeverylog = (text, interval = 30) => {
    // Only log every thirtieth call
    if (onceEvery(interval)) {
      privateOnceeverylog(text);
    }
  };

  // warnlog() goes to console.warn
  const warnlog = debug(componentNamespace);
  warnlog.log = console.warn.bind(console);

  // errlog() goes to console.error
  const errlogPrivate = debug(componentNamespace);
  errlogPrivate.log = console.error.bind(console);
  const errlog = (...args) => {
    // In production, this is how we report on handled errors that we are
    // still interested in.
    if (PRODUCTION) {
      const errString = Array.from(args).join(' '); // TODO: Check that no args are objects/functions
      Bugsnag.notify(new Error(errString));
    }
    errlogPrivate(...args);
  };

  // Return as one object that is easy to destructure
  return {
    log,
    onceeverylog,
    warnlog,
    errlog,
  };
};
