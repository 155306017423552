export default (function onceEvery() {
  let i = 0;
  // The function that is returned
  return function fullMoon(every) {
    i += 1;
    return i % every === 0;
  };
})();

// Closure that returns true every X: https://stackoverflow.com/a/20831186/750544
