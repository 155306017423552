// Lib
import React, { memo, useEffect } from 'react';
import { StyleSheet, css } from 'aphrodite';
// Local
import debug from '../util/debug';
// Debug
const { log } = debug('elv:components:Backdrop');

const s = StyleSheet.create({
  background: {
    backgroundColor: '#ccc',
    position: 'fixed',
    top: '-10%',
    left: '-10%',
    width: '120%',
    height: '120%',
  },
});

/**
 * Configuration: See server/public/particles.config.json
 * Source code: https://github.com/VincentGarreau/particles.js/
 * Experimentation: https://vincentgarreau.com/particles.js/
 */
const Backdrop = () => {
  useEffect(() => {
    /* particlesJS.load(@dom-id, @path-json, @callback (optional)); */
    // eslint-disable-next-line no-undef
    particlesJS.load('nodes-backdrop', 'particles.config.json', () => {
      log('backdrop loaded');
    });
  }, []);

  return <div className={css(s.background)} id="nodes-backdrop" />;
};

export default memo(Backdrop);
