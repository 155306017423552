/* eslint-disable react/sort-comp */
// Lib
import React, { PureComponent } from 'react';
import { StyleSheet, css } from 'aphrodite';
import { Button, Form, Header, Image, Input, Transition } from 'semantic-ui-react';
// Local
import debug from '../../util/debug';
// Warning: DO NOT import icons here, icon lists expose a surprising amount of info about the system.
// Debug
const { log, warnlog } = debug('elv:login:LoginForm');

const s = StyleSheet.create({
  form: {
    backgroundColor: 'white', // Transparent did not work quite as well as hoped here, but `rgba(255, 255, 255, 0.8)` is fine
    borderRadius: '1rem',
    boxShadow: '0px 3px 5px 0px #777',
    padding: '2rem',
    userSelect: 'none', // Prevent selection of text elements in form (happens when user clicks to add nodes in Backdrop)
    // Positioning
    position: 'relative',
    top: '50%',
    left: '50%',
    width: '19rem', // or 'max-content',
    // Note: Margins are set manually by resizer to ensure proper
    // centering without interrupting click listeners in Backdrop.
  },
  headerBox: {
    // Because the logo loads after the box, the logo will mess with the layout,
    // to prevent that we are dedicating space to the logo beforehand.
    height: 50,
  },
});

class LoginForm extends PureComponent {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.resize = this.resize.bind(this);
    this.boxRef = React.createRef();
    this.usernameRef = React.createRef();
    this.passwordRef = React.createRef();
    this.currentTimeout = null;
    let message = '';
    if (window.messages && window.messages.length !== 0) {
      // Messages is array, but just get the first one for now. Will expand later if needed.
      if (window.messages.includes('LOGGED_OUT')) {
        message = 'Logged out';
      }
    }
    this.state = {
      error: '',
      loading: false,
      message,
      showPassword: false,
      visible: true,
    };
  }

  componentDidMount() {
    // The component is not properly positioned when mounted. To fix this we first
    // immediately "hide" the component (without unmounting). Also, do not set
    // opacity in StyleSheet because we cannot override opacity with !important later.
    this.boxRef.style.opacity = 0;
    // Note: Initial offsetWidth / offsetHeight is not fully calculated after mount,
    // however we can add a simple setTimeout to ensure that this calculation runs
    // after React is done building the initial tree.
    setTimeout(this.resize, 0); // Fix sizing (placement) and show the component

    // Focus on first empty field
    const { defaultValue } = this.usernameRef.props;
    const hasUsername = typeof defaultValue !== 'undefined' && 0 < this.usernameRef.props.defaultValue.length;
    if (hasUsername) {
      this.passwordRef.focus();
    } else {
      this.usernameRef.focus();
    }

    // If showing a hydrated message from the HTML template, then hide it shortly.
    // eslint-disable-next-line react/destructuring-assignment
    if (this.state.message) {
      setTimeout(() => {
        this.setState({ message: '' });
      }, 2000);
    }
  }

  resize() {
    if (this.boxRef) {
      const left = (this.boxRef.offsetWidth / 2) * -1;
      const top = (this.boxRef.offsetHeight / 2) * -1;
      this.boxRef.style.marginLeft = `${left}px`;
      this.boxRef.style.marginTop = `${top}px`;
      this.boxRef.style.opacity = '1.0';
    } else {
      warnlog('resize called when this.boxRef was falsy:', this.boxRef);
    }
  }

  handleSubmit(event) {
    event.preventDefault();
    const data = {
      username: event.target.username.value,
      password: event.target.password.value,
    };
    clearTimeout(this.currentTimeout);
    // Start loading (and reset error)
    this.setState({ error: '', loading: true }, async () => {
      // Send credentials
      const response = await fetch('/login-data', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      // Evaluate response to login request and react accordingly
      const { status } = response;
      // const { statusText } = response;
      // const body = await response.text();
      // log(status, statusText, body);

      if (status === 204) {
        // Store username for next login
        localStorage.username = data.username;
        // Success, go to main
        window.location = '/';
      } else if (status === 205) {
        // Store username for next login
        localStorage.username = data.username;
        // Success, follow redirect
        const returnPath = response.headers.get('Location');
        window.location = returnPath;
      } else if (status === 401) {
        // Invalid username/password
        const { visible } = this.state;
        this.setState({ loading: false, visible: !visible });
      } else if (status === 500) {
        // Error, purposely non-descript
        this.setState({ error: 'Something went wrong', loading: false }, () => {
          this.currentTimeout = setTimeout(() => {
            this.setState({ error: '' });
          }, 2000);
        });
      }
    });
  }

  /**
   * Why have we used the favicon as a logo? Simple: The favicon is
   * both tiny, and probably cached, so it loads super fast.
   */
  render() {
    const { error, loading, message, showPassword, visible } = this.state;

    let button = null;
    if (message !== '') {
      button = <Button content={message} fluid type="submit" />;
    } else if (error !== '') {
      button = <Button color="red" content={message} fluid type="submit" />;
    } else {
      button = (
        <Transition animation="shake" duration="500" visible={visible}>
          <Button content="Log in" fluid icon="sign-in" loading={loading} type="submit" />
        </Transition>
      );
    }

    const handleMouseDown = () => this.setState({ showPassword: true });
    const handleMouseUp = () => this.setState({ showPassword: false });

    return (
      <div
        className={css(s.form)}
        ref={(c) => {
          this.boxRef = c;
        }}
      >
        <div className={css(s.headerBox)}>
          <Header as="h3" textAlign="center">
            <Image size="medium" src="/favicon.png" />
            Elv
          </Header>
        </div>

        <Form onSubmit={this.handleSubmit}>
          <Form.Field>
            <label>Username</label>
            <Input
              autoComplete="username"
              defaultValue={localStorage.username}
              icon="user"
              iconPosition="left"
              name="username"
              placeholder="hello@elv.tech"
              ref={(c) => {
                this.usernameRef = c;
              }}
              required
              type="text"
            />
          </Form.Field>
          <Form.Field>
            <label>Password</label>
            <Input
              autoComplete="current-password"
              icon="lock"
              iconPosition="left"
              label={{
                icon: showPassword ? 'eye slash' : 'eye',
                onBlur: handleMouseUp,
                onMouseDown: handleMouseDown,
                onMouseOut: handleMouseUp,
                onMouseUp: handleMouseUp,
              }}
              labelPosition="right corner"
              name="password"
              placeholder="password"
              ref={(c) => {
                this.passwordRef = c;
              }}
              required
              type={showPassword ? 'text' : 'password'}
            />
          </Form.Field>
          {button}
        </Form>
      </div>
    );
  }
}

export default LoginForm;
